<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="admin-dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Dashboard')}}</h2>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="TruckIcon"
          :statistic="dashboardStats.total_deliveries"
          :statisticTitle="$t('Successful Deliveries')"
          color="success"
        ></statistics-card-line>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="PackageIcon"
          :statistic="dashboardStats.total_packages"
          :statisticTitle="$t('Total Packages')"
          color="success"
        ></statistics-card-line>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="PackageIcon"
          :statistic="dashboardStats.delivery_attempts"
          :statisticTitle="$t('Delivery Attempts')"
          color="warning"
        ></statistics-card-line>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          hideChart
          :dataLoading="dashboardStatsLoading"
          icon="DollarSignIcon"
          :statistic="Number(dashboardStats.gross_revenue_plus_vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})"
          :statisticTitle="$t('Gross Revenue + VAT')"
          color="success"
        ></statistics-card-line>
      </div>

      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="DollarSignIcon"
          :statistic="Number(dashboardStats.gross_revenue).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})"
          :statisticTitle="$t('Gross Revenue')"
          color="success"
        ></statistics-card-line>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="PackageIcon"
          :statistic="dashboardStats.failed_attempts"
          :statisticTitle="$t('Failed Attempts')"
          color="danger"
        ></statistics-card-line>
      </div>

      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="DollarSignIcon"
          :statistic="Number(dashboardStats.net_revenue).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})"
          :statisticTitle="$t('Net Revenue')"
          color="warning"
        ></statistics-card-line>
      </div>

      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="DollarSignIcon"
          :statistic="Number(dashboardStats.discounts_value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})"
          :statisticTitle="$t('Discounts')"
          color="danger"
        ></statistics-card-line>
      </div>

      <div class="vx-col w-full lg:w-1/3 mb-base">
        <statistics-card-line
          :dataLoading="dashboardStatsLoading"
          hideChart
          icon="DollarSignIcon"
          :statistic="Number(dashboardStats.total_cod_collected_to_date).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})"
          :statisticTitle="$t('Total CoD Collected To Date')"
          color="success"
        ></statistics-card-line>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '../../components/statistics-cards/StatisticsCardLine.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
export default {
  data () {
    return {
      dashboardStats: {
        total_deliveries: 0,
        total_packages: 0,
        delivery_attempts: 0,
        gross_revenue_plus_vat: 0,
        gross_revenue: 0,
        failed_attempts: 0,
        net_revenue: 0,
        discounts_value: 0,
        total_cod_collected_to_date: 0
      },
      dashboardStatsLoading: false

    }
  },
  methods: {
    loadDashbord () {
      this.dashboardStatsLoading = true
      sendRequest(true, false, this, 'api/v1/analytics/admin/dashboard/', 'get', null, true,
        (response) => {
          this.dashboardStats = response.data
          this.dashboardStatsLoading = false
        }
      )
    }
  },
  components: {
    StatisticsCardLine
  },
  created () {
    this.loadDashbord()
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#admin-dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
